<template>
  <section id="full-store-preview" style="position: relative;">
    <Client_App :admin_imported="true"/>
  </section>
</template>

<script>
import Client_App from "../../../../App"
import { admin_domain } from "../../../../data/other_constants"

export default {
  components: {
    Client_App
  },
  mounted() {
    const package_version_arr = process.env.VUE_APP_VERSION.split(".")
    const styles_url = `${
      process.env.NODE_ENV === "development" ? `https://${admin_domain}` : window.location.origin
    }/css/app.styles-${
      package_version_arr[0]
    }.${
      // Because after deploy, the version gets increased and pushed so dev has higher version
      Number(package_version_arr[1]) - (process.env.NODE_ENV === "development" ? 1 : 0)
    }.${
      package_version_arr[2]
    }.css`

    const link = document.createElement("link")
    const iframe = document.getElementById("store-preview-iframe")

    link.rel = "stylesheet"
    link.href = styles_url

    iframe.contentWindow.document.head.appendChild(link)
  }
}
</script>
